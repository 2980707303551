import React from 'react';
import OpenAI from './OpenAI';
import './styles.css';

const App = () => {
  return (
    <div className='app'>
      <img src="./img/logo.jpeg" alt="Logo" className="logo" />
      <h1>Star Wars GTP</h1>
      <OpenAI />
      <footer>
      <div className='lang'>
        <a href="https://starwarsgpt.cloud/"><img src="./img/flag-uk.png" alt="English" /></a>
        <a href="https://starwarsgpt.cloud/?lang=fr"><img src="./img/flag-france.png" alt="Français" /></a>
      </div>
      Author: Vincent Misson - <a href="https://twitter.com/StarWarsGPT">Twitter</a> <br />
      Powered by <a href="https://azure.microsoft.com/en-us/products/cognitive-services/openai-service">Azure OpenAI Service</a>
    </footer>
    </div>
  );
};

export default App;