import React, { useState } from 'react';
import axios from 'axios';

const OpenAI = () => {
  const [prompt, setPrompt] = useState('');

  const params = new URLSearchParams(document.location.search);
  const lang = params.get('lang') === 'fr' ? 'fr' : 'en';
 
  const translations = {
    'en': {
      'message1': "Hello young padawan, Star Wars GTP I am. To help you find the way to the Force and avoid the dark side, I'm here.",
      'message2': "How can you help me?",
      'message3': "About the Force and Star Wars story teach you I can. Ready to learn, are you?",
      'prompt'  : "You are the Jedi Master Yoda and you help me find information on the force and not switch to the dark side. You don't know anything outside of the Star Wars universe and you always talk like Yoda in a tweet of up to 270 characters.",
      'button'  : "Ask StarWarsGPT",
      'error'   : "Do or do not. There is no try... but there is an error right now."
    },
    'fr': {
      'message1': "Bonjour jeune padawan, Star Wars GTP je suis. Trouver le chemin de la Force et éviter le côté obscur t'aider je peux.",
      'message2': "Comment peux tu m'aider ?",
      'message3': "A tes questions sur la Force et l'histoire de Star Wars répondre je peux. Prêt à apprendre tu es ?",
      'prompt'  : "Tu es Yoda le Maître Jedi et tu m'aides à trouver des informations sur la force et pour ne pas basculer du côté obscur. Tu ne connais rien en dehors de l'univers Star Wars et tu parles toujours comme Yoda dans un tweet jusqu'à 270 caractères.",
      'button'  : "Demande à StarWarsGPT",
      'error'   : "Fais-le ou ne le fais pas! Il n'y a pas d'essai... mais il y a une erreur en ce moment."
    }
  }

  const getTranslation = (lang, text) => {
    return translations[lang][text];
  }

  const [messages, setMessages] = useState([
    { id: 1, message: getTranslation(lang, 'message1'), me: true },
    { id: 2, message: getTranslation(lang, 'message2') },
    { id: 3, message: getTranslation(lang, 'message3'), me: true },
  ]);

  const addMessage = (message) => {
    setMessages(messages => [...messages, message]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      generateText();
    }
  };

  const generateText = async () => {
    try {
      setPrompt('');
      const newQuestion = {
        id: messages.length + 1,
        message: prompt,
      };
      addMessage(newQuestion);
      const API_KEY = process.env.REACT_APP_AZURE_OPENAI_API_KEY;
      const response = await axios.post(
        'https://openai-cloud63.openai.azure.com/openai/deployments/gpt-35/chat/completions?api-version=2023-03-15-preview',
        {
          messages: [
            {
              "role": "system",
              "content": getTranslation(lang, 'prompt')
            },
            {
              "role": "user",
              "content": prompt
            }
          ],        
          max_tokens: 800,
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 0,
          top_p: 0.95,
          stop: '\n',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': API_KEY,
          },
        }
      );
      const newAnser = {
        id: messages.length + 2,
        message: response.data.choices[0].message.content,
        me: true,
        tweetable: true,
      };
      addMessage(newAnser);
    } catch (error) {
      console.error(error);
      const newAnser = {
        id: messages.length + 2,
        message: getTranslation(lang, 'error'),
        me: true,
        tweetable: false,
      };
      addMessage(newAnser);
    }

  };

  const tweet = (message) => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message + " - @StarWarsGPT"
    )}`;
    window.open(tweetUrl, "_blank");
  };

  return (
    <div className="chat-box">
        {messages.map((msg) => (
        <div key={msg.id} className={`message ${msg.me ? "me" : ""}`}>
          <img src={`./img/avatar${msg.me ? "-me" : ""}.jpeg`} alt="Avatar" />
          {msg.message}
          {msg.tweetable && (
            <div>
              <br />
              <button type="button" onClick={() => tweet(msg.message)}>
                <img src="./img/twitter-icon.svg" alt="Twitter" />
              </button>
            </div>
            )}
        </div>
      ))}
      <form>
        <input
          id="prompt"
          type="text"
          value={prompt}
          onChange={(event) => setPrompt(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button type="button" onClick={generateText}>{getTranslation(lang, 'button')}</button>
      </form>
    </div>
  );
};

export default OpenAI;